<template>
  <div class="admin-menu" v-if="$isCreator">
    <div class="admin-menu-content">
      <div class="admin-menu-user" v-if="$user">
        <div class="admin-menu-user-avatar tcenter">
          <UserAvatar :user="$user" size="60px" @click="goToUserFeed($user)" cursor="pointer" />
          <div class="admin-menu-user-name">{{ $user.name }}</div>
          <div class="admin-menu-user-role" tcenter>
            <small><MyRoleLabel /></small>
          </div>
        </div>
      </div>
      <div class="admin-menu-options" v-if="$screen.width < 1040">
        <router-link class="primary-circle userProfileButton" :to="`${$isCreator ? `/${$user.user}` : $links['my-profile']}`">
          <iconic name="user" />
        </router-link>
        <a class="primary-circle" @click="CreatorMenuMobile"><iconic name="bars" /></a>
      </div>
      <div class="admin-menu-nav" v-else>
        <AdminMenuOptions />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    AdminMenuOptions: () => import("../admin/AdminMenuOptions.vue"),
  },
  methods: {
    CreatorMenuMobile: function() {
      this.$store.commit("CreatorMenuMobile", true);
    },
  },
};
</script>
